<template>
  <form @submit.prevent="submitForm">
    <div class="columns">
      <div class="column is-flex is-aligned-center p-t-xl">
        <croppie-wrapper
          ref="profile_picture"
          v-model="updateProfilePictureForm.profile_picture"
          :min-width="120"
          :min-height="120"
          :settings="croppieSettings"
          :crop-options="croppieSettings.cropOptions"
          :enforce-boundary="false"
          is-circle
          text-size="7"
          entity="UsersProfileImage"
          @removed="removeProfilePicture"
          @save="uploadProfilePicture"
        >
          {{ $t('forms.upload_profile_picture') }}
        </croppie-wrapper>
      </div>

      <div class="column">
        <form-field
          :validator="$v.updatePreferencesForm.first_name"
          :label="$t('forms.enter_first_name')"
        >
          <input
            v-model="updatePreferencesForm.first_name"
            :placeholder="$t('forms.first_name')"
            class="input"
            type="text"
            name="name"
            @input="$v.updatePreferencesForm.first_name.$touch()"
          >
        </form-field>

        <form-field
          :validator="$v.updatePreferencesForm.last_name"
          :label="$t('forms.enter_last_name')"
        >
          <input
            v-model="updatePreferencesForm.last_name"
            :name="$t('forms.last_name')"
            :placeholder="$t('forms.your_surname')"
            class="input"
            type="text"
            @input="$v.updatePreferencesForm.last_name.$touch()"
          >
        </form-field>

        <form-field
          :validator="$v.updatePreferencesForm.country"
          :label="$t('forms.country')"
        >
          <country-picker
            v-model="updatePreferencesForm.country"
            @change="$v.updatePreferencesForm.country.$touch()"
          />
        </form-field>

        <form-field
          :validator="$v.updatePreferencesForm.locale"
          :label="$t('general.language')"
        >
          <language-picker
            v-model="updatePreferencesForm.locale"
            :placeholder="$t('forms.choose_language')"
            popper-class="Preferences_LanguageModal"
            class="Preferences_LanguagePicker"
            show-supported-only
            show-language-variations
          />
        </form-field>

        <form-field
          #default="{ validator }"
          :validator="$v.updatePreferencesForm.phone"
          :server-errors="errors.phone"
          :label="$t('general.phone')"
        >
          <phone-input
            v-model="validator.$model"
            :country="userCountry"
            :dirty="validator.$dirty"
            data-testid="phoneInput"
            @error="handlePhoneErrors"
          />
        </form-field>

        <form-field
          :validator="$v.updatePreferencesForm.timezone"
          :label="$t('general.time_zone')"
        >
          <timezone-picker
            v-model="updatePreferencesForm.timezone"
            popper-class="Preferences_TimezoneModal"
          />
        </form-field>

        <form-field
          :validator="$v.updatePreferencesForm.lockout_time"
          :label="$t('forms.lockout_time')"
        >
          <lockout-time-picker v-model="updatePreferencesForm.lockout_time" />
        </form-field>
      </div>
    </div>

    <div class="field is-flex">
      <v-button
        :loading="isRequestingInfo"
        class="is-outlined is-black"
        type="button"
        @click.prevent="requestUserInfoDump"
      >
        {{ $t('pages.settings.personal.preferences.dump_user_info_button') }}
      </v-button>

      <v-button
        :loading="updatePreferencesForm.isLoading"
        class="is-primary m-l-a"
        data-testid="SavePreferences"
      >
        {{ $t('forms.save') }}
      </v-button>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'

import PhoneInput from '@hypefactors/shared/js/components/core/PhoneInput'
import { croppieDataFactory, croppieDefaultSettings } from '@hypefactors/shared/js/factories/croppie'

import Form from '@/services/forms/Form'
import timezones from '@/constants/timezones'

import TimezonePicker from '@/components/core/TimezonePicker'
import LockoutTimePicker from '@/components/forms/LockoutTimePicker'

const updateProfilePictureForm = new Form({
  profile_picture: { value: croppieDataFactory() }
})

const updatePreferencesForm = new Form({
  first_name: { value: '', rules: { required } },
  last_name: { value: '', rules: { required } },
  phone: { value: '', rules: {} },
  locale: { value: '', rules: { required } },
  timezone: { value: '', rules: { required } },
  country: { value: '', rules: { required } },
  referrer: { value: [] },
  lockout_time: { value: 0 }
})

export default {
  components: {
    LockoutTimePicker,
    TimezonePicker,
    PhoneInput
  },

  validations: {
    updateProfilePictureForm: updateProfilePictureForm.rules(),
    updatePreferencesForm: updatePreferencesForm.rules()
  },

  data () {
    return {
      isRequestingInfo: false,

      croppieSettings: croppieDefaultSettings(120, 120),

      updateProfilePictureForm: updateProfilePictureForm,
      updatePreferencesForm: updatePreferencesForm,

      updateProfilePictureCancelToken: null,
      updatePreferencesCancelToken: null,
      requestInfoCancelToken: null,

      errors: {
        phone: ''
      }
    }
  },

  computed: {
    ...mapGetters(['currentUser']),

    timezones () {
      return timezones
    },

    userCountry () {
      return this.$safeGet(this.currentUser, 'country.data', {
        iso_alpha2: null,
        iso_alpha3: null
      })
    }
  },

  beforeDestroy () {
    this.updateProfilePictureCancelToken && this.updateProfilePictureCancelToken.cancel()

    this.updatePreferencesCancelToken && this.updatePreferencesCancelToken.cancel()

    this.requestInfoCancelToken && this.requestInfoCancelToken.cancel()
  },

  mounted () {
    this.mergeUserToForm()

    if (!this.currentUser.has_password) {
      this.$confirm(this.$t('pages.settings.personal.confirm_complete_account'), {
        showCancelButton: false
      }).catch(() => {})
    }
  },

  methods: {
    ...mapActions(['fetchUserRequest', 'fetchUserBrandsRequest']),

    mergeUserToForm () {
      this.updatePreferencesForm.merge({
        ...this.currentUser,
        country: this.userCountry.iso_alpha2
      })

      this.updateProfilePictureForm.merge({
        profile_picture: this.currentUser.avatar
      })
    },

    async submitForm () {
      this.$v.updatePreferencesForm.$touch()

      if (this.$v.updatePreferencesForm.$error || this.errors.phone) {
        return
      }

      this.updatePreferencesCancelToken = this.$api.cancelToken()

      try {
        await this.updatePreferencesForm.submit('put', 'user', {
          updatePreferencesCancelToken: this.updatePreferencesCancelToken.token
        })

        this.$notify.success(this.$t('success.user_updated'))

        // Fetch the user and the brands again so we have logos and Brand Members data updated.
        await Promise.all([
          this.fetchUserRequest(),
          this.fetchUserBrandsRequest()
        ])
      } catch (error) {
        if (this.$api.isCancelToken(error)) {
          return
        }

        this.$displayRequestError(error)
      }
    },

    async uploadProfilePicture () {
      this.$v.updateProfilePictureForm.$touch()

      if (this.$v.updateProfilePictureForm.$error) {
        return
      }

      this.updateProfilePictureCancelToken = this.$api.cancelToken()

      try {
        await this.$refs.profile_picture.uploadImage()
      } catch (e) {
        if (this.$api.isCancelToken(e)) {
          return
        }

        this.$displayRequestError(e, this.$t('errors.cannot_update_brand_logo'))

        return
      }

      try {
        await this.updateProfilePictureForm.submit('post', 'user/profile-picture', {
          cancelToken: this.updateProfilePictureCancelToken.token
        })

        this.$notify.success(this.$t('success.user_profile_picture_updated'))

        // Fetch the user again so we have logos data updated
        await this.fetchUserRequest()
      } catch (error) {
        if (this.$api.isCancelToken(error)) {
          return
        }

        this.$displayRequestError(error)
      }
    },

    async removeProfilePicture () {
      this.$refs.profile_picture.setIsUploadingStatus(true)

      try {
        await this.$api.delete('user/profile-picture')

        this.$notify.success(this.$t('success.user_profile_picture_removed'))

        // Fetch the user again so we have logos data updated
        await this.fetchUserRequest()
      } catch (err) {
        this.$displayRequestError(err, this.$t('errors.error'))
      } finally {
        this.$refs.profile_picture.setIsUploadingStatus(false)
      }
    },

    async requestUserInfoDump () {
      if (!this.isRequestingInfo) {
        try {
          this.requestInfoCancelToken = this.$api.cancelToken()

          await this.$confirm(this.$t('pages.settings.personal.preferences.confirmation_text'))

          this.isRequestingInfo = true

          await this.$api.post('user/download-information', {
            cancelToken: this.requestInfoCancelToken.token
          })

          this.$notify.success(this.$t({
            message: this.$t('pages.settings.personal.preferences.request_for_user_info_run'),
            duration: 10000
          }))
        } catch (err) {
          if (this.$api.isCancelToken(err)) {
            return
          }

          this.$displayRequestError(err, this.$t('errors.error'))
        } finally {
          this.isRequestingInfo = false
        }
      }
    },

    handlePhoneErrors (isInvalid) {
      this.errors.phone = isInvalid ? this.$t('validations.isValidPhone') : ''
    }
  }
}
</script>
