<template>
  <div class="PhoneInput columns is-mobile">
    <div class="column is-3">
      <country-picker
        v-model="valueCountry"
        :disabled="disabled"
        popper-class="PhoneInputCountryPickerDropdown"
        class="PhoneInputCountryPicker"
        value-prop="iso_alpha3"
        @change="updateValue"
      />
    </div>
    <div class="column is-9">
      <input
        v-model="valueLocal"
        type="text"
        class="input PhoneInput__Input"
        :class="{ 'is-danger': !isValid && dirty }"
        :disabled="disabled"
        placeholder="your phone number, ie 897 765 432"
        @blur="updateValue"
      >
    </div>
  </div>
</template>

<script>
import phone from 'phone'
import CountryPicker from './CountryPicker'

/**
 * @module PhoneInput
 */
export default {
  name: 'PhoneInput',
  components: { CountryPicker },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      required: true
    },
    country: {
      type: Object,
      default: null
    },
    dirty: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      valueCountry: '',
      valueLocal: ''
    }
  },
  computed: {
    formatted () {
      return phone(this.valueLocal, this.valueCountry)
    },
    isValid () {
      return !this.valueLocal ? true : this.formatted.length
    }
  },
  watch: {
    value (value, oldValue) {
      if (value === oldValue) return

      const result = phone(value)

      if (result.length) {
        this.valueLocal = result[0]
        this.valueCountry = result[1]
      }
    }
  },
  mounted () {
    this.valueLocal = this.value
    this.country && (this.valueCountry = this.country.iso_alpha3)
  },
  methods: {
    updateValue () {
      this.$emit('error', !this.isValid)
      this.$emit('input', this.formatted[0] || '')
    }
  }
}
</script>
